import React from 'react'
import ArticleCard from '../../components/ArticleCard'

const SectionHighlight = ({ articles, section, noImage, isConnected, sectionLabel, useH1, withReadCount }) => (
  articles.map((article, index) => {
    if (index) {
      return <ArticleCard key={article.contentKey} article={article} noImage={noImage} width={100} withReadCount={withReadCount} />
    } else {
      return <ArticleCard key={article.contentKey} article={article} showAbstract noImage={noImage} width={315} withReadCount={withReadCount} />
    }
  })
)

export default SectionHighlight
