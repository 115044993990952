import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { getSectionLabel } from 'ion-sections'
import ArticleCard from '../../components/ArticleCard'
import { RightArrow } from 'ion-icon'

const Default = ({ articles, section, noImage, isConnected, sectionLabel, withReadCount }) => {
  const highlightLabel = sectionLabel || getSectionLabel(section)
  return articles.map((article, index) => {
    return (
      <Fragment key={article.contentKey}>
        {index === 0 && (
          <>
            <div className='section-head'>
              <h2><span>{getSectionLabel(highlightLabel)}</span></h2>
              <Link to='/multimedia'>More <RightArrow width='12' height='12' /></Link>
            </div>
            <ArticleCard key={article.contentKey} article={article} showAbstract showPlayBtn noImage={noImage} width={305} withReadCount={withReadCount} />
          </>
        )}
        {index > 0 && (
          <ArticleCard key={article.contentKey} article={article} noImage={noImage} width={100} withReadCount={withReadCount} />
        )}
      </Fragment>
    )
  })
}

export default Default
