import React from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { Articles, HomePageMeta } from 'ion-article-cmp'
import Ad from '../../components/Ad'
import { FORMATS, MAPPINGS } from '../../components/AdFormats'
import NoImage from '../../static/no-image.png'
import HomePageTemplate from '../../templates/Articles/HomePageHighlight'
import SectionHighlight from '../../templates/Articles/SectionHighlight'
import MultimediaHighlightTemplate from '../../templates/Articles/MultimediaHighlight'
import SiteSidebar from '../../components/Sidebar'
import WingBanners from '../../components/WingBanners'
import logo from '../../static/general/daily-voice.jpg'
import { MobileElse } from 'ion-media'
import { Link } from 'react-router-dom'
import { RightArrow } from 'ion-icon'
import useHasMounted from '../../components/useHasMounted'

const LeaderboardTop = [
  [[1024, 0], [[728, 90], [970, 90], 'fluid']],
  [[640, 0], [[460, 60], 'fluid']],
  [[0, 0], [[320, 100], [320, 50], [300, 100], [300, 50], 'fluid']]
]

const Leaderboard = [
  [[1024, 0], [[728, 90], [970, 90], [970, 250], 'fluid']],
  [[640, 0], [[460, 60], 'fluid']],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50], 'fluid']]
]

const Wing = [
  [[1024, 0], [[160, 600], [120, 600]]],
  [[640, 0], []],
  [[0, 0], []]
]

const HomePage = (props) => {
  const hasMounted = useHasMounted()
  return (
    <>
      <WingBanners useSmall={props.useSmall} isConnected={props.isConnected} location={props.location} onSlotRenderEndedLeft={props.onSlotRenderEndedLeft} onSlotRenderEndedRight={props.onSlotRenderEndedRight} mappingLeft={Wing} mappingRight={Wing} />
      <Helmet title={process.env.RAZZLE_SITE_TITLE}>
        <meta property='og:url' content={process.env.RAZZLE_SITE_URL} />
        <meta name='description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
        <meta itemProp='description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
        <meta property='og:description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
        <meta name='twitter:site' content='@dailyvoicesa' />
        <meta name='twitter:creator' content={process.env.RAZZLE_SITE_TWITTER_USER} />
        <meta name='twitter:description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
        <meta name='twitter:title' content={process.env.RAZZLE_SITE_DESCRIPTION} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:image:src' content={logo} />
      </Helmet>
      <HomePageMeta {...props} />
      <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/homepage`} slotId='interstitial' targeting={{ interstitial: 'interstitial' }} collapseEmptyDiv className='advert-leaderboard' anchor='interstitial' mapping={MAPPINGS.Interstitial} {...FORMATS.interstitial} />
      <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/homepage`} slotId='leaderboard-top' targeting={{ leaderboard: 'top' }} collapseEmptyDiv className='advert-leaderboard-top' mapping={LeaderboardTop} {...FORMATS.leaderboardtop} />
      {/* <div className='wrapper breaking-news'>
      <span>Breaking News</span>
      <a href='/'>
          Breaking News headline goes here
      </a>
    </div> */}
      <Articles {...props} component={HomePageTemplate} className='wrapper featured-articles' section='tvbox' count={7} pageSize={7} noImage={NoImage} />
      <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/homepage`} slotId='leaderboard-top1' targeting={{ leaderboard: 'top1' }} collapseEmptyDiv className='advert-leaderboard' mapping={Leaderboard} {...FORMATS.leaderboard} />
      <div className='wrapper article-listing'>
        <div className='article-list'>
          {/* NEWS Section */}
          <div className='section-head'>
            <h1><span>News</span></h1>
            <Link to='/news'>More <RightArrow width='12' height='12' /></Link>
            <div className='related-sections'>
              <p>Related Sections</p>
              <nav>
                <ul>
                  <li><Link to='/news/western-cape'>Western Cape</Link></li>
                  <li><Link to='/news/national'>National</Link></li>
                  <li><Link to='/news/international'>International</Link></li>
                </ul>
              </nav>
            </div>
          </div>
          <Articles {...props} component={SectionHighlight} className='highlights' section='news' count={5} pageSize={5} noImage={NoImage} />

          {/* SPORT Section */}
          <div className='section-head'>
            <h2><span>Sport</span></h2>
            <Link to='/sport'>More <RightArrow width='12' height='12' /></Link>
            <div className='related-sections'>
              <p>Related Sections</p>
              <nav>
                <ul>
                  <li><Link to='/sport/cricket'>Cricket</Link></li>
                  <li><Link to='/sport/rugby'>Rugby</Link></li>
                  <li><Link to='/sport/soccer'>Soccer</Link></li>
                </ul>
              </nav>
            </div>
          </div>
          <Articles {...props} component={SectionHighlight} className='highlights' section='sport' count={5} pageSize={5} noImage={NoImage} />

          {/* SPORT Section */}
          <div className='section-head'>
            <h2><span>Lifestyle &amp; Entertainment</span></h2>
            <Link to='/lifestyle-entertainment'>More <RightArrow width='12' height='12' /></Link>
            <div className='related-sections'>
              <p>Related Sections</p>
              <nav>
                <ul>
                  <li><Link to='/lifestyle-entertainment/celebrity'>Celebrity</Link></li>
                  <li><Link to='/lifestyle-entertainment/lifestyle'>Lifestyle</Link></li>
                  <li><Link to='/lifestyle-entertainment/travel'>Travel</Link></li>
                </ul>
              </nav>
            </div>
          </div>
          <Articles {...props} component={SectionHighlight} className='highlights' section='lifestyle-entertainment' count={5} pageSize={5} noImage={NoImage} />

          <Articles {...props} component={MultimediaHighlightTemplate} className='highlights multimedia' section='multimedia' count={5} pageSize={5} noImage={NoImage} />

          {/* COLUMNS & OPINION Section */}
          <div className='section-head'>
            <h2><span>Columns &amp; Opinion</span></h2>
            <Link to='/opinion'>More <RightArrow width='12' height='12' /></Link>
            <div className='related-sections'>
              <p>Related Sections</p>
              <nav>
                <ul>
                  <li><Link to='/opinion/sport'>Sport</Link></li>
                  <li><Link to='/opinion/motoring'>Motoring</Link></li>
                  <li><Link to='/opinion/current-affairs'>Current Affairs</Link></li>
                </ul>
              </nav>
            </div>
          </div>
          <Articles {...props} component={SectionHighlight} className='highlights' section='opinion' sectionLabel='Columns &amp; Opinion' count={5} pageSize={5} noImage={NoImage} />
        </div>
        <SiteSidebar {...props} section='homepage' />
      </div>
      {hasMounted &&
        <MobileElse>
          <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/homepage`} slotId='leaderboard-bot' targeting={{ leaderboard: 'bot' }} collapseEmptyDiv className='advert-leaderboard' mapping={Leaderboard} {...FORMATS.leaderboard} />
        </MobileElse>}
    </>
  )
}

const mapStateToProps = (state) => ({ articles: state.articles })

export default connect(mapStateToProps)(HomePage)
