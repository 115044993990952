import React from 'react'
import { Desktop, defaultWidth } from 'ion-media'
import Ad from './Ad'
import FORMATS from './AdFormats'

const WingBanners = ({ useSmall, isConnected, location, onSlotRenderEndedLeft, onSlotRenderEndedRight, mappingRight, mappingLeft }) => {
  return (
    <span>
      {process.env.NODE_ENV === 'production' &&
        <Desktop values={{ deviceWidth: defaultWidth }}>
          <div className={'wing-banners horizontal-center' + (useSmall ? ' reduced-header' : '')}>
            <Ad isConnected={isConnected} path={`/${process.env.RAZZLE_DFP_CODE}${location.pathname === '/' ? '/homepage' : location.pathname}`} slotId='wingbanner-left' targeting={{ wingbanner: 'left' }} onSlotRenderEnded={onSlotRenderEndedLeft} collapseEmptyDiv className='advert-wingbanner-left' mapping={mappingLeft} {...FORMATS.wingbanner} />
            <Ad isConnected={isConnected} path={`/${process.env.RAZZLE_DFP_CODE}${location.pathname === '/' ? '/homepage' : location.pathname}`} slotId='wingbanner-right' targeting={{ wingbanner: 'right' }} onSlotRenderEnded={onSlotRenderEndedRight} collapseEmptyDiv className='advert-wingbanner-right' apping={mappingRight} {...FORMATS.wingbanner} />
          </div>
        </Desktop>}
    </span>
  )
}

export default WingBanners
